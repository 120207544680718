<template>
<a-card :border="false">
    <div class="realtor">
        <!-- 查询表单 -->
        <a-form-model ref="searchForm" layout="horizontal" :label-col="{xs:6}" :wrapper-col="{xs:18}">
            <a-row :gutter="8">
                <a-col :md="8" :xl="6" :xxl="6" :sm="12">
                    <a-form-model-item label="部门名称">
                        <a-input v-model="searchData.name" placeholder="部门名称"></a-input>
                    </a-form-model-item>
                </a-col>
                <a-col :xxl="6" :xl="5" :md="8" sm="2">
                    <a-form-model-item :label-col="{span: 0}" :wrapper-col="{span:24}">
                        <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
                        <a-button type="default" @click="reset()" icon="sync">重置</a-button>
                    </a-form-model-item>
                </a-col>
            </a-row>
        </a-form-model>
        <div>
            <a-button type="primary" v-if="onAdd" @click="toHandler('add')">新增</a-button>
            <a-button type="primary" v-if="onEdit" @click="toHandler('edit')">修改</a-button>
            <a-button type="default" v-if="onDelete" @click="toHandler('del')">删除</a-button>
        </div>
      <a-row :gutter="8">
        <a-col :md="6" :xxl="6">
          <div class="tree-outer" style="border:1px solid #e8e8e8;">
            <a-tree :treeData="dataList"
              v-if="dataList.length>0"
              :expanded-keys="expandedKeys"
              @expand="onExpand"
              @select="handleSelect">
            </a-tree>
          </div>
        </a-col>
        <a-col :md="18" :xl="18">
          <!-- 数据表格 -->
          <a-table :loading="tableLoading" :row-selection="rowSelection" :rowKey="record => record.id"
                   :columns="columns" :data-source="tableData" bordered :pagination="page"
                   @change="changeTable" :customRow="changeTableRow" :rowClassName="rowClassName">
            <span slot="flagEnable" slot-scope="text, row">
                <a-switch v-model="row.flagEnable" @change="onChangeStatus(row)" />
            </span>
          </a-table>
        </a-col>
      </a-row>

        <!-- 编辑表单 -->
        <DealerDepartment-edit-modal ref="DealerDepartmentEditModal" @reload="getData"></DealerDepartment-edit-modal>
    </div>
</a-card>

</template>

<script>
import { columns } from './components/colums.js'
import DealerDepartmentEditModal from './components/DealerDepartmentEditModal.vue'
import { delDealerDepartment, listDealerDepartment, selectByIdDealerDepartment } from './api/DealerDepartmentApi'
import { treeData } from '@/utils/treeutil'
import { checkPermission } from '@/utils/permissions'

const pageSource = {
    current: 1,
    pageSize: 10,
    total: 0
}
export default {
    name: 'dealerDepartment',
    components: {
        DealerDepartmentEditModal
    },
    data() {
        return {
            columns: columns,
            tableData: [],
            searchData: {}, // 搜索条件
            scroll:{ y: 600 },
            tableLoading: false, //表格loading
            page: {
                current: 1,
                pageSize: 10,
                total: 0,
                showSizeChanger: true,
                showTotal: () => `共${this.page.total}条`,
            },
            selectedRowKeys: [],
            selectedRows: [],
            onAdd: checkPermission('dealer:dept:add'),
            onEdit: checkPermission('dealer:dept:edit'),
            onDelete: checkPermission('dealer:dept:delete'),
            expandedKeys: [0],
            dataList: [],
            StationId: 0
        }
    },
    computed: {
        rowSelection() {
            const _this = this
            return {
                fixed: true,
                type: 'radio', //"radio"
                selectedRowKeys: this.selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    _this.selectedRowKeys = selectedRowKeys
                    _this.selectedRows = selectedRows
                }
            }
        }
    },
    methods:{
        // 隔行换色
        rowClassName(record,index) {
            let className = "light-row"
            if (index % 2 === 1) className = "dark-row"
            return className;
        },
        /**
         * 获取表格数据
         */
        getData() {
            this.tableLoading = true
            this.selectedRowKeys = []
            this.selectedRows = []
            listDealerDepartment ({
                pageNumber: this.page.current,
                pageSize: this.page.pageSize,
                ...this.searchData
            }).then(res => {
                if (res.code === 200) {
                    const { total, records } = res.body
                    this.page.total = total
                    this.tableData = treeData(records)
                    // this.tableData = records
                }
            }).finally(() => this.tableLoading = false)
        },
        /**
         * 筛选查询
         */
        getDataFilter() {
            this.page.current = 1;
      this.page.pageSize = 10
            this.getData()
        },
        // 获取左侧树
        getTreeList() {
          this.axios.post('/api/dealer/dealer/dealerInfo/getTree').then(res => {
            this.dataList = res.body
          })
        },
        onExpand(expandedKeys) {
          this.expandedKeys = expandedKeys;
          this.autoExpandParent = false;
        },
        // 选中筛选
        handleSelect(value) {
          let id = Number(value.toString())
          this.StationId = id
          this.getData()
        },
        /**
         * 重置
         */
        reset() {
            this.searchData = {}
            this.page = Object.assign({}, this.page)
            this.getData()
        },
        /**
         * 更改分页
         */
        changeTable(pagination) {
            this.page = pagination
            this.getData()
        },
        /**
         * 点击行选中
         */
        changeTableRow(record) {
            return {
                on: {
                    click: e => {
                        console.log(e)
                        if (this.selectedRowKeys[0] === record.id) {
                            this.selectedRowKeys = []
                            this.selectedRows = []
                        } else {
                            this.selectedRowKeys = [record.id]
                            this.selectedRows = [record]
                        }
                    }
                }
            }
        },
        // 修改状态
        onChangeStatus(row) {
            this.axios.get('/api/base/system/department/statusDealer/' + row.id).then(res => {
                if(res.code == 200) {
                    this.$message.success(res.message)
                    this.getData()
                }
            })
        },
        toHandler(name) {
            const _this = this
            if (name === 'add') {
                _this.$refs.DealerDepartmentEditModal.setRowData({}, 'add')
                return
            }
            if (_this.selectedRows.length === 0) {
                _this.$notification.warning({
                    message: '请选择一条记录'
                })
                return
            }
            switch (name) {
                case 'edit':
                    _this.$refs.DealerDepartmentEditModal.setRowData(_this.selectedRows[0], 'edit')
                    break
                case 'del':
                    _this.$confirm({
                        title: '删除警告',
                        content: '确认要删除此条记录吗?',
                        okText: '确认',
                        cancelText: '取消',
                        async onOk() {
                            const res = await delDealerDepartment(_this.selectedRows[0].id)
                            if (res.code === 200) {
                                _this.$notification.success({ message: res.message })
                                _this.getData()
                            } else {
                                _this.$notification.error({ message: res.message })
                            }
                        },
                        onCancel() {}
                    })
                    break
            }
        },
    },
    created() {
        this.getData()
        this.getTreeList()
    }
}
</script>

<style lang="less" scoped>
    .ant-btn {
        margin-right: 8px;
        margin-bottom: 12px;
    }
    .tree-outer {
      height: 500px;
      overflow-y: auto;
    }
    ::v-deep .ant-table-tbody > .light-row {
        background: #FFF!important;;
    }
    ::v-deep .ant-table-tbody > .dark-row { 
        background: #fafafa!important;
    }
</style>
